<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-toolbar dark flat color="purple darken-1">
            <v-toolbar-title>Create Product</v-toolbar-title>
          </v-toolbar>
          <v-progress-linear class="pa-0 ma-0" color="success" height="5" :indeterminate="loading"></v-progress-linear>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="newProduct.name"
                  @change="generateSlug()"
                  counter="70"
                  :rules="rules"
                  label="Name"
                  autofocus
                ></v-text-field>
                <v-text-field outlined :value="newProduct.slug" label="URL" v-show="false" disabled></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                  outlined
                  v-model.number="newProduct.price"
                  type="number"
                  mask="##################"
                  suffix="SR"
                  label="Price"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                  outlined
                  v-model="newProduct.sku"
                  @change="generateSlug()"
                  counter="30"
                  :rules="rules"
                  label="SKU"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                  outlined
                  type="number"
                  mask="###############"
                  v-model.number="newProduct.stock"
                  label="Stock"
                ></v-text-field>
              </v-col>
            </v-row>
            <quill-editor v-model="newProduct.description"></quill-editor>
          </v-card-text>
        </v-card>
        <v-col v-if="this.newProduct.features.length" class="px-0">
          <v-card>
            <v-toolbar dark flat color="purple darken-1">
              <v-toolbar-title>Features</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr v-for="item in newProduct.features" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.option }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-col>
      <v-col class="py-0" cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark flat color="purple darken-1">
                <v-toolbar-title>
                  <v-switch v-model="newProduct.active" label="Active" hide-details></v-switch>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    to="/admin/products"
                    depressed
                    class="light--text"
                    color="purple darken-1"
                  >Cancel</v-btn>
                  <v-btn :loading="loading" @click="saveEverything(newProduct)" text>Save</v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                <v-select
                  outlined
                  v-model="category"
                  @change="setCategory"
                  item-text="name"
                  return-object
                  :loading="loadingCategory"
                  :items="allProductCategories"
                  label="Category"
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card v-if="features.length !== 0">
              <v-toolbar dark flat color="purple darken-1">
                <v-toolbar-title>Features</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="d-flex" v-for="(feature, index) in features" :key="index">
                <v-select
                  class="mr-5"
                  outlined
                  v-model="option"
                  :items="feature.options"
                  :label="feature.name"
                  :disabled="optionIndex != index"
                ></v-select>
                <v-btn
                  :disabled="optionIndex != index"
                  outlined
                  height="56"
                  @click="setFeature(feature.name, index)"
                  color="purple"
                >Add</v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark flat color="purple darken-1">
                <v-toolbar-title>Upload Images</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn :loading="loading" @click="deleteAllImage()" depressed text>Reset All</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-img
                  height="100"
                  contain
                  @click="selectImage"
                  :src="require('@/assets/admin/media/files/photo-camera.svg')"
                ></v-img>
                <input
                  type="file"
                  ref="uploadImage"
                  multiple
                  @change="uploadImages"
                  style="display:none"
                  accept=".png, .jpg, .jpeg"
                />

                <div v-for="(image, index) in newProduct.images" :key="image">
                  <v-img v-if="image" height="100" :src="image">
                    <v-btn
                      dark
                      red
                      position-y
                      icon
                      @click="deleteImage(image, index)"
                      color="danger"
                    >
                      <v-icon>delete_forever</v-icon>
                    </v-btn>
                  </v-img>
                </div>
                <!-- Product Image Uploading Progress Start -->
                <v-dialog v-model="uploadingImage" hide-overlay persistent width="300">
                  <v-card color="primary" dark>
                    <v-card-text>
                      {{ progress }} % Uploading Image..
                      <v-progress-linear :value="progress" color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!-- Product Image Uploading Progress End -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Snackbar  -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarMessage }}
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <!-- /Snackbar  -->
    </v-row>
  </v-container>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import firebase from "firebase";
//import { db } from "@/db"

import { mapGetters, mapActions } from "vuex";
import { truncate } from "fs";

export default {
  name: "AddProducts",
  components: { quillEditor },
  data() {
    return {
      rules: [],
      progress: 0, //Image Uploading Progress
      uploadingImage: false, //Popup for image uploading progress
      loading: false, // Loading
      loadingCategory: false, // loading Categories
      //Snackbar
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: "",
      // Form Data
      category: {},
      features: [],
      option: "",
      optionIndex: 0,
      newProduct: {
        name: "",
        description: "",
        price: 0,
        stock: 0,
        sku: "",
        slug: "",
        active: true,
        images: [],
        category: {},
        categoryId: "",
        tags: [],
        features: []
      }
    };
  },
  methods: {
    ...mapActions(["add", "bind"]),

    slugify(text, ampersand = "and") {
      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, c => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    selectImage() {
      this.$refs.uploadImage.click();
    },
    uploadImages(image) {
      let images = image.target.files;

      Object.keys(images).forEach(key => {
        let image = images[key];
        // console.log(image)
        this.uploadImage(image);
      });
    },

    uploadImage(image) {
      this.loading = true; //Loading
      this.uploadingImage = true; //Active image uploading progress popup
      if (image) {
        // console.log(image);
        let file = image;
        let time = Date.now();
        // File or Blob named mountains.jpg

        let storageRef = firebase
          .storage()
          .ref("products/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            //console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.newProduct.images.push(downloadURL); // Push Image Links to arrey
              this.uploadingImage = false; // Deactive image uploading progress popup
              this.loading = false; // Loading
            });
          }
        );
      }
    },
    deleteImage(imageUrl, index) {
      // console.log(imageUrl,index)
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);
      //Remove from Data: newProduct.images[]
      this.newProduct.images.splice(index, 1);

      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          //  console.log("Image Deleted")
          // File deleted successfully
          this.snackbarMessage = "Image deleted successfully";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.loading = false;
        })
        .catch(error => {
          //  console.log("Error on deleting image")
          // Uh-oh, an error occurred!
          this.snackbarMessage = "Uh-oh, an error occurred!";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
        });
    },
    deleteAllImagesTask(imageUrl, index) {
      // console.log(imageUrl,index)
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);

      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          // console.log("Image Deleted")
          //Remove from Data: newProduct.images[]
          this.newProduct.images = [];
          // File deleted successfully
          this.snackbarMessage = "Image deleted successfully";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.loading = false;
        })
        .catch(error => {
          // console.log("Error on deleting image");
          // Uh-oh, an error occurred!
          this.snackbarMessage = "Uh-oh, an error occurred!";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
        });
    },
    resetForm() {
      this.newProduct = {
        name: "",
        description: "",
        price: 0,
        stock: 0,
        sku: "",
        slug: "",
        active: true,
        images: [],
        category: {},
        categoryId: "",
        tags: [],
        features: []
      };
      this.category = {};
      this.resetFeatures();
      // this.$nextTick(() => {
      //   this.category = {};
      // });
    },
    saveEverything(newProduct) {
      this.loading = true;
      if (newProduct.name != "") {
        return this.add({
          collection: "products",
          data: newProduct
        })
          .then(() => {
            this.resetForm();
            this.loading = false;
            this.snackbarMessage = "Created new product";
            this.snackbarColor = "success";
            this.snackbar = true;
            // this.goBack()
          })
          .catch(error => {
            this.loading = false;
            this.snackbarMessage = error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      } else {
        this.loading = false;
        this.snackbarMessage = "Oops! Not provided name of the product";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
    resetEverything() {
      this.deleteAllImage();
      this.resetForm();
      this.snackbarMessage = "Everything Reseted";
      this.snackbarColor = "success";
      this.snackbar = true;
    },
    deleteAllImage() {
      let images = this.newProduct.images;
      images.forEach((image, index) => {
        // console.log('Link',image,'ID',index)
        this.deleteAllImagesTask(image, index);
      });
    },
    setCategory(category) {
      // console.log(category);
      this.resetFeatures();
      this.newProduct.category = {
        id: category.id,
        active: category.active,
        name: category.name,
        description: category.description,
        image: category.image,
        slug: category.slug
      };

      this.newProduct.categoryId = category.id;
      this.features = category.features;
    },
    setFeature(name, index) {
      const feature = {
        name: name,
        option: this.option
      };
      this.newProduct.features.push(feature);
      this.newProduct.tags.push(this.option);
      this.optionIndex++;
    },
    resetFeatures() {
      (this.option = ""),
        (this.optionIndex = 0),
        (this.newProduct.features = []),
        (this.newProduct.tags = []),
        (this.features = []);
    },
    generateSlug() {
      const slugData = this.newProduct.name + "_" + this.newProduct.sku;
      this.newProduct.slug = this.slugify(slugData);
    }
  },
  computed: {
    ...mapGetters(["allProductCategories"])
  },
  created() {
    this.loadingCategory = true;
    this.bind({
      collection: "productCategories",
      data: "productCategories"
    }).then(() => (this.loadingCategory = false));
  },
  beforeDestroy() {
    this.resetEverything();
  }
};
</script>

<style></style>
